.app_demo_main {
  height: 100vh;
  background-color: #18303b;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app_image {
  justify-content: center;
  display: flex;
}

.app_image img {
  height: 400px;
  width: auto;
}

.app_name {
  justify-content: center;
  display: flex;
  color: #ffffff99;
  font-size: 30px;
  padding-top: 20px;
  font-family: "archivo-black";
}

#l_name {
  color: yellow;
}

#b_name {
  color: #6ecfc6;
}

#h_name {
  color: deepskyblue;
}

@media (max-width: 650px) {
  .app_demo_main {
    height: auto;
    padding: 100px 0px;
  }

  .app_name {
    padding-bottom: 50px;
    padding-top: 15px;
  }
}

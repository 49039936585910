.footer_main {
  background-color: black;
  padding-top: 50px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.last_row {
  padding-top: 50px;
}

#founder_image_col {
  padding-left: 0;
}

#founder_title {
  color: #6ecfc6;
  font-weight: bold;
  padding-bottom: 10px;
}

#co_founder_title {
  color: #6ecfc6;
  font-weight: bold;
  padding-bottom: 10px;
  padding-top: 20px;
}

#founder_name {
  color: #fff;
}

#founders_image {
  height: 50px;
  width: 50px;
}

#founder_style {
  color: rgba(255, 255, 255, 0.5);
}

#founder_name_style {
  font-weight: bolder;
  color: #fff;
}

#footer_logo {
  width: 100px;
}

#footer_licence {
  text-align: end;
  color: #fff;
  font-size: small;
  margin: 0 !important;
}

#footer_lience_col {
  justify-content: flex-end;
  align-self: flex-end;
  display: flex;
}

#easy_access_links {
  justify-content: flex-end;
  display: flex;
  padding-bottom: 10px;
  margin: 0;
  color: #fff;
}

#contact_row {
  padding-top: 10px;
  color: #6ecfc6;
  font-weight: bold;
  justify-content: end;
  padding-right: 12px;
}

#contact_add {
  color: #fff;
  font-size: 12px;
  justify-content: end;
  text-align: end;
}

#contact_mail {
  color: #fff;
  padding-bottom: 5px;
  font-size: 12px;
  justify-content: end;
  text-align: end;
}

#contact_add:hover,
#contact_mail:hover {
  color: #6ecfc6;
}

#easy_access_links:hover {
  color: #6ecfc6;
}

@media (max-width: 768px) {
  .easy_access {
    padding-top: 30px;
  }

  #footer_lience_col {
    justify-content: flex-start;
    align-self: flex-start;
    display: flex;
    padding-top: 10px;
  }

  #easy_access_links {
    justify-content: flex-start;
    align-self: flex-end;
    margin: 0;
  }

  .last_row {
    padding-top: 30px;
  }

  #contact_row {
    padding-top: 10px;
    font-weight: bold;
    justify-content: start;
    padding-left: 12px;
  }

  #contact_add {
    color: #fff;
    font-size: 12px;
    justify-content: start;
    text-align: start;
    padding-left: 12px;
  }

  #contact_mail {
    justify-content: start;
    text-align: start;
  }
}

@media (min-width: 576px) {
  #footer_lience_col {
    justify-content: flex-end;
    align-self: flex-end;
    display: flex;
  }
}

.how_main {
  height: 100vh;
  background-color: #18303b;
  color: #fff;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 0 100px;
}

.how_heading {
  color: #6ecfc6;
  font-size: 2.5rem;
  font-weight: 700;
}

.how_first {
  margin: 1rem;
  display: flex;
  align-items: center;
}

.first-img {
  margin: 1rem;
  margin-right: 5rem;
}
.first {
  text-align: left;
}

.first ul {
  letter-spacing: 2px;
}

.first h4 {
  letter-spacing: 2px;
  font-weight: 900;
}

.how_second {
  margin: 1rem;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.second-img {
  margin: 1rem;
  margin-left: 5rem;
}
.second {
  text-align: left;
}

.second ul {
  letter-spacing: 2px;
}

.second h4 {
  letter-spacing: 2px;
  font-weight: 900;
}

.how_third {
  margin: 1rem;
  display: flex;
  align-items: center;
}

.third-img {
  margin: 1rem;
  margin-right: 5rem;
}
.third {
  text-align: left;
}

.third ul {
  letter-spacing: 2px;
}

.third h4 {
  letter-spacing: 2px;
  font-weight: 900;
}

@media only screen and (max-width: 480px) {
  .how_content {
    margin-bottom: 2rem;
    /* margin-top: 2rem; */
  }
  .how_main {
    height: auto;
  }

  .how_heading {
    font-size: 2rem;
    margin-top: 1rem;
  }

  .how_first,
  .how_second,
  .how_third {
    flex-direction: column;
    margin-bottom: 2.5rem;
  }

  .first,
  .second,
  .third {
    text-align: center;
  }

  .first-img,
  .second-img,
  .third-img {
    margin: 1rem;
  }

  .first-img img,
  .second-img img,
  .third-img img {
    height: 120px;
    width: 120px;
  }

  .first h4,
  .second h4,
  .third h4 {
    font-size: 1.1rem;
  }
  .first ul,
  .second ul,
  .third ul {
    margin: 1rem;
    padding: 0;
    list-style: none;
    text-align: center;
    font-size: 0.8rem;
    letter-spacing: 0.5px;
  }
  .first-img img {
    height: 70px;
    width: auto;
  }
}

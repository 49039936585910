.who_main {
  height: 100vh;
  background-color: #18303b;
  color: #fff;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 100px;
}

.who_heading {
  text-align: center;
  color: #6ecfc6;
  font-size: 2.5rem;
  font-weight: 700;
}

.who_content {
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  color: white;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: lighter;
  display: flex;
  margin: 1rem;
}

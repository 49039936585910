@font-face {
  font-family: "archivo-black";
  src: url("/src/fonts/archivo-black/ArchivoBlack.otf");
}

@font-face {
  font-family: "tt-lakes-neue-expa";
  src: url("/src/fonts/tt-neue-expa/TTLakesNeueExp-Regular.otf");
}

.div_main {
  height: 92vh;
  align-items: center;
  justify-content: center;
  background-color: #18303b;
  width: 100%;
  background-image: url("../../images/background_home.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  border-bottom-style: solid;
  border-bottom-width: 5px;
  border-bottom-color: #d9d9d9;
}

.title {
  color: #fff;
  text-align: center;
  font-size: 9rem;
  padding-top: 50px;
}

.desc {
  color: #fff;
  text-align: center;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

#title_tail {
  font-family: "tt-lakes-neue-expa";
  font-size: 7rem;
}

#title_head {
  color: #6ecfc6;
  font-family: "archivo-black";
}

#bolt_image {
  height: 3.6rem;
  margin-top: 18px;
  padding: 0;
}

@media (max-width: 650px) {
  .div_main {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
  }

  .title {
    color: #fff;
    text-align: center;
    font-size: 5rem;
    padding-top: 180px;
  }

  #title_tail {
    font-family: "tt-lakes-neue-expa";
    font-size: 4.5rem;
  }

  .desc {
    font-size: 1.1rem;
  }

  #bolt_image {
    height: 2rem;
    margin-top: 10px;
  }
}
